import React from 'react'

export default function Airsalonpas({color}) {
    return (
        <svg height='60' viewBox="0 0 176.56 135.15" xmlns="http://www.w3.org/2000/svg" >
            <g>
                <g>
                    <polygon points="153.8 39.28 173.82 34.84 171.96 28.99 153.8 39.28"
                             fill={color}/>
                    <polygon points="155.81 48.75 174.33 57.61 176.56 50.64 155.81 48.75"
                             fill={color}/>
                    <polygon points="149.99 57.71 165.56 69.86 160.6 73.88 149.99 57.71"
                             fill={color}/>
                </g>
                <g>
                    <polygon points="24.43 112.97 5.55 120.95 8.44 126.37 24.43 112.97"
                             fill={color}/>
                    <polygon points="20.74 104.03 .93 98.65 0 105.91 20.74 104.03"
                             fill={color}/>
                    <polygon points="24.85 94.16 7.35 85.02 11.5 80.17 24.85 94.16"
                             fill={color}/>
                </g>
                <g>
                    <path
                        d="M132.87,48.67c-.58-2.1-.15-4.35,1.17-6.09l18.26-23.92c.52-.68-4.51-5.39-11.23-10.53-6.72-5.13-12.59-8.74-13.11-8.06l-18.26,23.92c-1.32,1.73-3.38,2.75-5.56,2.74h0c-2.18,0-4.24,1-5.56,2.74l-60.92,79.81c-1.63,2.14,4.46,9.54,13.6,16.52,9.15,6.98,17.89,10.91,19.52,8.76l60.92-79.81c1.32-1.73,1.76-3.99,1.17-6.09h0Z"
                        fill={color}/>
                    <path
                        d="M55.43,102.4s5.91-3.78,13.31-15.81c7.49-12.17,9.16-16.67,12.74-22.24,1.6-2.49,8.66-14.01,21.28-23.72l.49.38s-5.46,4.82-11.87,13.22c-5.62,7.36-11.43,18.84-12.26,20.26s-11.3,21.04-23.2,28.3l-.51-.39Z"
                        fill={`#fff`}/>
                    <path
                        d="M102.77,40.63c-12.62,9.71-19.68,21.23-21.28,23.72-3.58,5.57-5.25,10.07-12.74,22.24-7.41,12.04-13.31,15.81-13.31,15.81l-.64-.49s5.91-3.78,13.31-15.81c7.49-12.17,9.16-16.67,12.74-22.24,1.6-2.49,8.66-14.01,21.28-23.72l.64.49Z"
                        fill={`#fff`}/>
                    <path
                        d="M120.89,54.46s-6.02,3.38-13.46,15.7c-7.38,12.23-8.94,16.77-12.45,22.39-1.57,2.51-8.24,14.4-21.46,23.66l-.58-.44s5.66-4.66,12.07-13.06c5.62-7.36,11.04-18.24,11.98-19.73,5.24-8.35,11.34-19.74,23.3-28.97l.6.46Z"
                        fill={`#fff`}/>
                    <path
                        d="M73.53,116.21c13.22-9.25,19.89-21.14,21.46-23.66,3.51-5.62,5.07-10.16,12.45-22.39,7.44-12.32,13.46-15.7,13.46-15.7l.76.58s-5.85,3.78-13.25,15.85c-6.77,11.04-11.15,20.11-12.62,22.28-1.63,2.4-8.64,13.95-21.49,23.62l-.76-.58Z"
                        fill={`#fff`}/>
                </g>
            </g>
        </svg>
    )
}
